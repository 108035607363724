import React, { Component } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown
} from 'reactstrap';

import { logOut } from '../../actions/userActions';
import { connect } from 'react-redux';

@connect(state => ({
  user: state.user
}))
class HeaderDropdown extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.logOut = this.logOut.bind(this);

    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  logOut() {
    const { dispatch } = this.props;
    dispatch(logOut());
    window.location = '/';
  }

  copyToken() {
    if( ! window.isSecureContex) {
      alert(localStorage.getItem('jwtToken'));
    } else {
      navigator.clipboard.writeText(localStorage.getItem('jwtToken'));
      alert("token copied");
    }
  }

  dropAccnt() {
    const { user } = this.props;
    return (
      <div>
        <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle nav className="m-3">
            <img src={'img/avatars/1.jpg'} className="img-avatar" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem disabled>
              <i className="icon-user" />
              {user.selected ? user.selected.name : null}
            </DropdownItem>
            <DropdownItem onClick={this.props.onSettingClick}>
              <i className="icon-settings" />
              Settings
            </DropdownItem>
            <DropdownItem onClick={this.copyToken}>
            <i className="icon-settings" />
              Copy token</DropdownItem>
            <DropdownItem onClick={() => this.logOut()}>
              <i className="icon-power" />
              Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  render() {
    const { ...attributes } = this.props;
    return this.dropAccnt();
  }
}

export default HeaderDropdown;
