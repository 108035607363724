import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import withRouter from '../../../withRouter';
import PropTypes from 'prop-types';

function getTabs(isBusinessCentral) {
  let Tabs = [
    {
      path: 'dashboard',
      title: 'Sync Dashboard',
      emptySourceDisable: true
    },
    {
      path: 'connection',
      title: 'Connection',
      processingDisable: true
    },
    {
      path: 'mappings',
      title: 'Mappings',
      emptySourceDisable: true,
      processingDisable: true
    },
    {
      path: 'filters',
      title: 'Filters',
      emptySourceDisable: true,
      processingDisable: true
    },
    {
      path: 'activities',
      title: 'Activities',
      emptySourceDisable: true
    },
    {
      path: 'failures',
      title: 'Failures',
      emptySourceDisable: true
    },
    {
      path: 'scheduler',
      title: 'Scheduler',
      emptySourceDisable: true,
      processingDisable: true
    },
    {
      path: 'diagnostics',
      title: 'Diagnostics',
      emptySourceDisable: true
    },
    {
      path: 'webhooks',
      title: 'Webhooks'
    }
  ];

  if (!isBusinessCentral) {
    Tabs = Tabs.filter((item) => item.path !== 'webhooks');
  }

  return Tabs;
}

function SyncMainTabs({ router, path, storeId, primaryDomain, sources, syncStage, sync2Mode, isBusinessCentral }) {
  const disableSettings = isEmpty(sources);

  const Tabs = getTabs(isBusinessCentral);

  return (
    <Nav tabs>
      {Tabs.map((tab) => (
        <NavItem key={tab.path}>
          <NavLink
            className={classnames({ active: router.location.pathname.endsWith(tab.path) })}
            disabled={
              (tab.emptySourceDisable && disableSettings) ||
              (tab.processingDisable && !!syncStage && syncStage !== 'COMPLETED') ||
              (!sync2Mode && tab.path != 'connection')
            }
            tag={Link}
            to={`${path}/${tab.path}`}
          >
            {tab.title}
          </NavLink>
        </NavItem>
      ))}
      <NavItem>
        <div style={{ display: 'block', padding: '0.5rem 1rem' }}>
          <span>Store ID: {storeId}</span>
          <span className="ms-4">
            URL:{' '}
            <a target="_blank" href={`https://${primaryDomain}`} rel="noreferrer">
              {primaryDomain}
            </a>
          </span>
        </div>
      </NavItem>
    </Nav>
  );
}

SyncMainTabs.propTypes = {
  match: PropTypes.object,
  router: PropTypes.object,
  storeId: PropTypes.string,
  path: PropTypes.string,
  primaryDomain: PropTypes.string,
  sources: PropTypes.object,
  syncStage: PropTypes.string,
  sync2Mode: PropTypes.bool,
  isBusinessCentral: PropTypes.bool,
};

export default connect((state) => ({
  sources: state.sync.source.sources,
  syncStage: state.sync.request.stage,
}))(withRouter(SyncMainTabs));
